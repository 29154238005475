import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Header() {
  const navTextColor = "whitesmoke";
  return (
    <>
      <Navbar expand="lg" bg="dark" data-bs-theme="dark" sticky="top">
        <Container
          style={{
            fontFamily: "CustomFont",
          }}
        >
          <Navbar.Brand href="/">
            <p className="App-heading-text-color">MML</p>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav>
              <Nav.Link style={{ color: navTextColor }} href="/">
                Home
              </Nav.Link>
              <Nav.Link style={{ color: navTextColor }} href="/physique">
                Physique
              </Nav.Link>
              <Nav.Link style={{ color: navTextColor }} href="/performance">
                Performance
              </Nav.Link>
              <Nav.Link style={{ color: navTextColor }} href="/about">
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
