import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home() {
  return (
    <>
      <Container
        fluid
        className="bg-dark"
        style={{
          color: "whitesmoke",
          fontFamily: "CustomFont",
          minHeight: "80vh",
        }}
      >
        <Row>
          <Col className="App-homepage-bg">
            <h1
              className="App-heading-text-color"
              style={{
                textAlign: "center",
              }}
            >
              Muscle Mechanics Lab
            </h1>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <h4
              className="App-heading-text-color"
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                textAlign: "center",
                paddingLeft: "10vw",
                paddingRight: "10vw",
              }}
            >
              Welcome to the Muscle Mechanics Lab
            </h4>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Here at the MML you can gain access to the most advanced and
            effective training programs available. Whether it is training for
            physique or sports performance, the MML provides a bespoke,
            optimised training program just for you. MML programs are developed
            by internationally recognised expert in resistance training for
            muscle hypertrophy, Dr. Gerard McMahon.
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Dr. McMahon engineers programs using the two governing principles of
            muscle - muscle mechanics and muscle physiology. Optimizing the
            magnitude, quality and frequency of mechanical signals to muscle
            ensures we maximize our muscle size, shape and performance.
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <video
              controls
              style={{ width: "90vw", height: "auto", padding: "20px" }}
            >
              <source src="/video/home-video.mp4" type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
