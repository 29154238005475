import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Instagram, Tiktok, Youtube } from "react-bootstrap-icons";

function Footer() {
  return (
    <>
      <Container
        fluid
        className="bg-dark"
        style={{
          color: "whitesmoke",
          fontFamily: "CustomFont",
        }}
      >
        <Row style={{ paddingLeft: "16px", paddingTop: "16px" }}>
          <Col>
            <h4 className="App-heading-text-color">Muscle Mechanics Lab</h4>
          </Col>
        </Row>
        <hr></hr>
        <Row style={{ padding: "16px" }}>
          <Col>
            <h5 className="App-heading-text-color">Get your program</h5>
            <ul
              style={{
                listStyle: "none",
                paddingLeft: "0",
                fontFamily: "Raleway, sans-serif",
              }}
            >
              <li>
                <b>Email: </b>
                <a
                  id="email-link"
                  href="mailto:info@musclemechanicslab.com"
                  style={{ color: "whitesmoke", textDecoration: "none" }}
                >
                  info@musclemechanicslab.com
                </a>
              </li>
              <li>
                <b>WhatsApp: </b>
                <a
                  id="phone-link"
                  href="https://wa.me/+447921000768"
                  style={{ color: "whitesmoke", textDecoration: "none" }}
                >
                  +447921000768
                </a>
              </li>
            </ul>
          </Col>
          <Col>
            <h5 className="App-heading-text-color">Explore</h5>
            <ul
              style={{
                listStyle: "none",
                paddingLeft: "0",
                fontFamily: "Raleway, sans-serif",
              }}
            >
              <li>
                <a
                  id="home-link"
                  href="/"
                  style={{ color: "whitesmoke", textDecoration: "none" }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  id="physique-link"
                  href="/physique"
                  style={{ color: "whitesmoke", textDecoration: "none" }}
                >
                  Physique
                </a>
              </li>
              <li>
                <a
                  id="performance-link"
                  href="/performance"
                  style={{ color: "whitesmoke", textDecoration: "none" }}
                >
                  Performance
                </a>
              </li>
              <li>
                <a
                  id="about-link"
                  href="/about"
                  style={{ color: "whitesmoke", textDecoration: "none" }}
                >
                  About
                </a>
              </li>
            </ul>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@musclemechanicslab"
            >
              <Tiktok
                className="App-link"
                style={{ margin: "16px" }}
                size={45}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/musclemechanicslab"
            >
              <Instagram
                className="App-link"
                style={{ margin: "16px" }}
                size={45}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@MuscleMechanicsLab"
            >
              <Youtube
                className="App-link"
                style={{ margin: "16px" }}
                size={45}
              />
            </a>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ textAlign: "center", fontFamily: "Raleway, sans-serif" }}
          >
            <p>Copyright &copy; Muscle Mechanics Lab</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
