import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import dumbbell from "../images/gym-dumbbell.jpg";
// import barbell from "../images/gym-barbell.jpg";
// import olympicRed from "../images/olympic-red.jpg";
import olympicDark from "../images/olympic-dark.jpg";
import gymPhoto from "../images/gym-photo.jpg";

function Performance() {
  return (
    <>
      <Container
        fluid
        className="bg-dark"
        style={{
          color: "whitesmoke",
          fontFamily: "CustomFont",
          minHeight: "80vh",
        }}
      >
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <h4
              className="App-heading-text-color"
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
            >
              Performance
            </h4>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              fontFamily: "Raleway, sans-serif",
              display: "flex",
              justifyContent: "center",
            }}
          >
            In terms of high-performance sport, the MML has two main areas of
            support:
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              fontFamily: "Raleway, sans-serif",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <b>
              <ol>
                <li>PERFORMANCE PROGRAMS</li>
                <li>PERFORMANCE STRATEGIES</li>
              </ol>
            </b>
          </Col>
        </Row>
        <Row>
          <Col
            className="App-section-col"
            style={{ fontFamily: "Raleway, sans-serif" }}
          >
            <h4
              className="App-heading-text-color"
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontFamily: "CustomFont",
              }}
            >
              Performance Programs
            </h4>
            MML Performance programs are individually tailored for the athletic
            development of each athlete or group of athletes. Programs can
            include prescription of resistance training, speed, power and/ or
            aerobic and anaerobic energy system development, whichever is
            appropriate to the athlete(s) and sport. Support in the delivery of
            the programs may also be available depending on athlete location.
            <br />
            <br />
            In order to support the holistic development of true high
            performance, additional services are available, such as the
            prescription of
            <ul>
              <li>Recovery protocols</li>
              <li>Nutrition</li>
              <li>
                Environmental performance prescription (training and/ or
                performing in heat or hypoxia)
              </li>
            </ul>
          </Col>
          <Col
            className="App-section-col"
            style={{ textAlign: "center", alignSelf: "center" }}
          >
            <img src={gymPhoto} style={{ maxWidth: "85%" }} alt="gymPhoto" />
          </Col>
        </Row>
        <hr></hr>
        <Row className="Reverse">
          <Col
            className="App-section-col"
            style={{ textAlign: "center", alignSelf: "center" }}
          >
            <img
              src={olympicDark}
              style={{ maxWidth: "85%" }}
              alt="olympicDark"
            />
          </Col>
          <Col
            className="App-section-col"
            style={{ fontFamily: "Raleway, sans-serif" }}
          >
            <h4
              className="App-heading-text-color"
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontFamily: "CustomFont",
              }}
            >
              Performance Strategies
            </h4>
            MML can work with athletes, coaches and managers to develop training
            and performance strategies. This can include development of annual
            plan (1-year) or major games cycle plan (2-4 year cycles).
            Performance strategies include
            <ul>
              <li>
                Periodisation of all physical training to allow optimal
                combinations of field and gym work for adaptation and
                performance
              </li>
              <li>
                Competition day performance strategies (priming, ergogenic aids
                etc.)
              </li>
              <li>
                Performing in the heat strategies (acclimation, cooling,
                aerosols etc.)
              </li>
              <li>Hypoxia (altitude) performance strategies (acclimation)</li>
              <li>Travel strategies (international travel)</li>
            </ul>
            Dr. Mc Mahon has &gt;20yrs experience in high performance sport,
            developing performance programs for Olympic/ Paralympic medalists,
            professional sports athletes e.g. Premier League Football,
            Premiership Rugby, PGA Tour, holding multiple professional
            accreditations in S&amp;C and physiology.
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Performance;
