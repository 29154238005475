import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import squat from "../images/gym-squat.jpg";

function Physique() {
  return (
    <>
      <Container
        fluid
        className="bg-dark"
        style={{
          color: "whitesmoke",
          fontFamily: "CustomFont",
          minHeight: "80vh",
        }}
      >
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <h4
              className="App-heading-text-color"
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
            >
              Physique
            </h4>
          </Col>
        </Row>
        <Row>
          <Col
            className="text-left"
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Only at MML can you gain access to Dr. McMahon's exclusive Maximal
            Effective Mechanical Tension (M.E.M.T.) training program. The
            M.E.M.T. program is the most advanced and potent training program
            for muscle hypertrophy, based on the most contemporary understanding
            of muscle biomechanics and physiology.
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <img src={squat} style={{ maxWidth: "80vw" }} alt="squat" />
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <h5 className="App-heading-text-color">
              What is the M.E.M.T. program based on?
            </h5>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Muscles are what we call a mechano-sensitive tissue, that is their
            main function is to detect mechanical signals.
            <b>
              <i> Mechanical tension </i>
            </b>
            is therefore the main determinant of muscle growth. The M.E.M.T.
            training program provides a range of exercises and ways to perform
            them that makes use of the effective higher mechanical tension
            within the exercise, whilst removing redundant movement and exercise
            techniques that have low mechanical tension. Only this way can your
            muscle fibres truly experience the anabolic stimulus to grow larger.
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            The second part of the M.E.M.T. program is the
            <b>
              <i> direction of mechanical tension </i>
            </b>
            (for muscle shape). The 3-D transmission of mechanical tension
            determines the direction of the force muscle cells feel, which in
            turn determines the shape in which they grow. The M.E.M.T. program
            contains exercises that will optimise muscle growth in the desired
            way.
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Physique;
