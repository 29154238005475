import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import gerardImage from "../images/Gerard-image.jpg";
import { Tiktok, Instagram, Youtube } from "react-bootstrap-icons";

function About() {
  return (
    <>
      <Container
        fluid
        className="bg-dark"
        style={{
          color: "whitesmoke",
          fontFamily: "Raleway, sans-serif",
          minHeight: "80vh",
        }}
      >
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "CustomFont",
            }}
          >
            <h4
              className="App-heading-text-color"
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
            >
              About the Muscle Mechanics Lab
            </h4>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            <Accordion data-bs-theme="dark">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Why choose GM Muscle Mechanics Lab for your training programs?
                </Accordion.Header>
                <Accordion.Body>
                  Our bespoke resistance training programs are all developed and
                  individually tailored to your needs by Dr. Gerard McMahon, a
                  recognized world-leading expert in resistance training for
                  muscle size, function and performance through publications of
                  research articles, textbook chapters and presentations at
                  major international conferences. Dr. McMahon was the first
                  person to develop the 'lengthened partial' training technique.
                  This is now recognized as a formidable and advanced training
                  strategy that has revolutionized the adaptations to resistance
                  training regarding muscle size and strength. With a PhD in
                  resistance training biomechanics and physiology, Dr. McMahon
                  has spent over 20 years working with elite team and individual
                  athletes including Olympic, Paralympic &amp; Commonwealth
                  Games medalists and professional athletes in football (Premier
                  League), rugby (Premiership) and the PGA tour but to name a
                  few. Dr. McMahon has also worked with champion powerlifting
                  and physique athletes. Muscle Mechanics Lab programs have been
                  tried and tested with some of the best athletes in the world
                  and now you have the opportunity to get access to them.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Why are GM Muscle Mechanics Lab programs completely unique?
                </Accordion.Header>
                <Accordion.Body>
                  All of our resistance training programs are based off the most
                  contemporary scientific evidence for enhancing muscle growth
                  and performance. As a result, Dr. McMahon has spent 20 years
                  in lab and working with top athletes to develop the
                  'M.E.M.T.'' program (the Maximal Effective Mechanical Tension
                  program). This world exclusive program combines all the
                  different areas of muscle stimuli such as muscle force, muscle
                  activation, muscle stretch with the art of resistance training
                  prescription, providing detailed exercises and workouts that
                  guarantee you are getting the most effective training
                  possible.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What do I get from a GM Muscle Mechanics Lab program?
                </Accordion.Header>
                <Accordion.Body>
                  Each program is developed specifically for you, for your body
                  and crucially, for your lifestyle. To do this, you will
                  receive free initial and follow-up consultations (depending on
                  program selected) with Dr. McMahon to plan out and develop
                  your bespoke program. Every person is unique - their genetics,
                  body shape, body type, their physiological responses to
                  training and of course the demands of their lifestyle.
                  Therefore, at the GM Muscle Mechanics Lab there is no one size
                  fits all generic program, everything done here is done
                  specifically to maximize
                  <b>
                    <i> your </i>
                  </b>
                  potential for training &amp; performance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Meet Dr. McMahon</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col
                      className="App-section-col"
                      style={{ fontFamily: "Raleway, sans-serif" }}
                    >
                      Dr McMahon is recognised as a world-leading expert in
                      training for muscle size, function and performance.
                      <br />
                      <br />
                      He brings over 20 years of experience training elite
                      professional athletes in various sports, both as part of a
                      team and on an individual basis.
                      <br />
                      <br />
                      This background equips him with a wealth of expertise and
                      qualifications to develop a personalized program tailored
                      to address your individual requirements.
                    </Col>
                    <Col
                      className="App-section-col"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={gerardImage}
                        style={{ maxWidth: "90%", height: "auto" }}
                        alt="gerard"
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            For more details on each program try the 'Physique' and
            'Performance' sections of the website.
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              paddingLeft: "10vw",
              paddingRight: "10vw",
              paddingBottom: "20px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Also check out our social media channels on Tiktok, Instagram and
            YouTube.
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@musclemechanicslab"
            >
              <Tiktok
                className="App-link"
                style={{ margin: "16px" }}
                size={45}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/musclemechanicslab"
            >
              <Instagram
                className="App-link"
                style={{ margin: "16px" }}
                size={45}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@MuscleMechanicsLab"
            >
              <Youtube
                className="App-link"
                style={{ margin: "16px" }}
                size={45}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
