import React from "react";
import "./App.css";
import Header from "./Components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Physique from "./Components/Physique";
import Performance from "./Components/Performance";
import About from "./Components/About";
import Footer from "./Components/Footer";
import { Button } from "react-bootstrap";

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/physique" element={<Physique />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </BrowserRouter>
      <Button
        href="mailto:info@musclemechanicslab.com"
        variant="light"
        style={{
          position: "fixed",
          bottom: "40px",
          right: "40px",
          zIndex: "9999",
          backgroundColor: "#3B9CFB",
          color: "whitesmoke",
          borderColor: "#3B9CFB",
        }}
      >
        Contact
      </Button>
      <Footer />
    </>
  );
}

export default App;
